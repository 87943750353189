.noting-section {
    display: block;
    height: 78vh;
    border-radius: 1rem;
    background-color: var(--main-col);
    margin-left: auto;
    margin-right: auto;
}
/* .focus-background {
    position:fixed;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    height:100vh;
    width:100vw;
    background-color: rgba(0,0,0,0);
    z-index: 1;
} */

form {
    margin: 1rem 3.5rem;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.textinput{
    height: 100%;
    width: 100%;
}
.title_input {
    font-family: "jost", sans-serif ;
    font-weight: 500 !important;
    font-size: 3rem !important;
    color: white;
    background-color: rgba(0,0,0,0);
    height: 20%;
    border: groove, var(--accent2-col), 2px;
    outline:none;
    width: inherit;
}
#text {
    height: 75%; 
    width: 100%;
    overflow: auto;
    outline: none;
    background-color: rgba(0,0,0,0);

    color: white;
    font-family: "jost", sans-serif ;
    font-size: 2rem;
}

.buttons {
    display: flex;
    position:relative;
    bottom:2%;
    width:100%;
}
.buttons button {
    bottom: 0;
    margin-right: 2rem;
    background-color: var(--accent-col);
    width:6rem;
}
.buttons .naah {
    background-color:black;
    opacity: 0.4;
}
