.nav {
    position: absolute;
    border-radius: 0 0 1rem 1rem;
    background-color: var(--main-col);
    width: 100vw;
    height: 3.5rem;
}

.innav {
    width: 80vw;
}