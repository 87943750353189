.notif__bg {
    position: absolute;
    display: flex;
    top:0;
    left:0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    justify-content: center;
    align-items: center;
}

.notif__content {
    display: flex;
    height: 30vh;
    width: 30vw;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    background-color: var(--main-col);
    opacity: 1;
}

.disabler {
    display:none
}