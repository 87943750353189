* {
  border: 0;
  margin: 0;
  font-family: "jost", sans-serif;
}

:root {
  --neutral-col: #F5EFE6;
  --main-col: #AEBDCA;
  --main2-col: #AED6D4;
  --accent-col: #7895B2;
  --accent2-col: #77BFBD;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

.bg {
  height: 100vh;
  background-color: var(--neutral-col);
}

.page {
  height: 100%;
  display: flex;
  align-items: center;
}

.bouton {
  margin:0 ;
  padding:0 ;
  text-align: center;
  letter-spacing: 1px;
  cursor:pointer;
  border-radius: 3px;
  display:inline-block;
  padding: 0 16px;
  text-transform: uppercase;
  vertical-align: middle;
  height: 2.3rem;
  line-height: 2.3rem;
  color: aliceblue;
  font-size: 0.9rem;
}

.disabler {
  display:none;
}

/* width */
::-webkit-scrollbar {
  width: 0.6rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.27);
  border-radius: 2rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--accent2-col);
  border-radius: 2ch;
}