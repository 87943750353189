.side-nav {
    display: flex;
    height: 78vh;
}

.side-nav__body {
    width: 25vw;
    margin-left: 0;
    height: 78vh;
    border-radius: 1rem 0 1rem 1rem;
    background-color: var(--main-col);
    transition: display 5ms;
}

.side-nav__container {
    margin: 0.5rem 0.7rem 1rem 0.7rem;
    height: 74vh;
}

.side-nav__container__header {
    margin:0 0 0.5rem 0;
    color: aliceblue;
    font-family: "jost", sans-serif;
    font-size: 1.7rem;
}
.side-nav__container ul {
    padding: 0;
    list-style-type: none;
    height: 60vh;
    overflow: auto;
}
.side-nav__container__li {
    list-style-type: none;
    display: block;
    overflow: hidden;
}

.side-nav__container__button {
    width: 100%;
    height: 3rem;
    
    margin:.2rem 0;
    line-height: 1.1rem;
    text-align: left;

    border-radius: 1rem;
    background-color: rgba(0,0,0, 0.1);
    
    color: aliceblue;
    font-family: "jost", sans-serif;
    font-size: 1.1rem;
}
.side-nav__container__button:hover {
    background-color: rgba(0,0,0, 0.4);
}
.new-note-button {
    font-size: 1.7rem;
    /* text-align: center; */
    /* display: grid;
    justify-content: space-around; */
}

.side-nav__toggle-button {
    height: 80px;
    width: 20px;
    background-color: var(--accent-col);
    border-radius: 0 1rem 1rem 0;
}

.inactive {
    display:none
}

.highlight {
    background-color: rgba(0,0,0, 0.4);
}